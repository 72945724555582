import Vue from 'vue'
import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    vats: [],
    loading: false,
    error: null,
  },
  getters: {
    vats: state => state.vats,
    loading: state => state.loading,
    error: state => state.error,
  },
  mutations: {
    SET_VATS(state, payload) {
      state.vats = payload
    },
    ADD_VAT(state, payload) {
      state.vats.push(payload)
    },
    UPDATE_VAT(state, payload) {
      const index = state.vats.findIndex(
        vat => vat.id === payload.id,
      )
      Vue.set(state.vats, index, payload)
    },
    DELETE_VAT(state, payload) {
      const index = state.vats.findIndex(
        category => category.id === payload,
      )
      state.vats.splice(index, 1)
    },
    SET_LOADING: (state, payload) => {
      state.loading = payload
    },
    SET_ERROR: (state, payload) => {
      state.error = payload
    },
    // eslint-disable-next-line no-return-assign
    CLEAR_ERROR: state => (state.error = null),
  },
  actions: {
    async getVats({ commit }) {
      try {
        commit('SET_LOADING', true)
        const { data } = await axiosIns.get('/vats')
        commit('SET_VATS', data)
        commit('SET_LOADING', false)
      } catch (error) {
        commit('SET_LOADING', false)
      }
    },
    async addVat({ commit }, payload) {
      try {
        commit('CLEAR_ERROR')
        commit('SET_LOADING', true)
        const { data } = await axiosIns.post('/vats/create/', payload)
        commit('ADD_VAT', data)
        commit('SET_LOADING', false)
      } catch (error) {
        commit('SET_LOADING', false)
        commit('SET_ERROR', error)
      }
    },
    async updateVat({ commit }, payload) {
      try {
        const { data } = await axiosIns.put(
          `/vats/update/${payload.id}/`,
          payload,
        )
        commit('UPDATE_VAT', data)
        commit('SET_LOADING', false)
      } catch (error) {
        commit('SET_LOADING', false)
        commit('SET_ERROR', error)
      }
    },
    async deleteVat({ commit }, payload) {
      try {
        await axiosIns.delete(`/vats/delete/${payload}`)
        commit('DELETE_VAT', payload)
        commit('SET_LOADING', false)
      } catch (error) {
        commit('SET_LOADING', false)
        commit('SET_ERROR', error)
      }
    },
  },
}
