import Vue from 'vue'
import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    drivers: [],
    loading: false,
    error: null,
  },
  getters: {
    drivers: state => state.drivers,
    loading: state => state.loading,
    error: state => state.error,
  },
  mutations: {
    SET_DRIVERS(state, payload) {
      state.drivers = payload
    },
    ADD_DRIVER(state, payload) {
      state.drivers.unshift(payload)
    },
    UPDATE_DRIVER(state, payload) {
      const index = state.drivers.findIndex(
        driver => driver.id === payload.id,
      )
      Vue.set(state.drivers, index, payload)
    },
    DELETE_DRIVER(state, payload) {
      const index = state.drivers.findIndex(
        driver => driver.id === payload,
      )
      state.drivers.splice(index, 1)
    },
    SET_LOADING: (state, payload) => {
      state.loading = payload
    },
    SET_ERROR: (state, payload) => {
      state.error = payload
    },
    // eslint-disable-next-line no-return-assign
    CLEAR_ERROR: state => (state.error = null),
  },
  actions: {
    async getDrivers({ commit }) {
      try {
        commit('SET_LOADING', true)
        const { data } = await axiosIns.get('/drivers/')
        commit('SET_DRIVERS', data)
        commit('SET_LOADING', false)
      } catch (error) {
        commit('SET_LOADING', false)
      }
    },
    async addDriver({ commit }, payload) {
      try {
        commit('CLEAR_ERROR')
        commit('SET_LOADING', true)
        const { data } = await axiosIns.post('/drivers/create/', payload)
        commit('ADD_DRIVER', data)
        commit('SET_LOADING', false)
      } catch (error) {
        commit('SET_LOADING', false)
        commit('SET_ERROR', error)
      }
    },
    async updateDriver({ commit }, payload) {
      try {
        const { data } = await axiosIns.put(
          `/drivers/update/${payload.id}/`,
          payload,
        )
        commit('UPDATE_DRIVER', data)
        commit('SET_LOADING', false)
      } catch (error) {
        commit('SET_LOADING', false)
        commit('SET_ERROR', error)
      }
    },
    async deleteDriver({ commit }, payload) {
      try {
        await axiosIns.delete(`/drivers/delete/${payload}`)
        commit('DELETE_DRIVER', payload)
        commit('SET_LOADING', false)
      } catch (error) {
        commit('SET_LOADING', false)
        commit('SET_ERROR', error)
      }
    },
  },
}
