export default [
  {
    path: '/work-certificates',
    name: 'work-certificates',
    component: () => import('@/views/workCertificates/Index.vue'),
    meta: {
      pageTitle: 'work Certificates',
      breadcrumb: [
        {
          text: 'work Certificates',
          active: true,
        },
      ],
      requireLogin: true,
    },
  },
  {
    path: '/work-certificates/create',
    name: 'create_work_certificate',
    component: () => import('@/views/workCertificates/Create.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Create a work certificate',
      breadcrumb: [
        {
          text: 'create new work certificate',
          active: true,
        },
      ],
    },
  },
  {
    path: '/work-certificates/:id',
    name: 'show-work-certificate',
    component: () => import('@/views/workCertificates/Show.vue'),
    meta: {
      requireLogin: true,

    },
  },
]
