import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import products from './routes/products'
import purchaseOrders from './routes/purchase/purchaseOrders'
import orders from './routes/sales/orders'
import invoices from './routes/sales/invoices'
import estimates from './routes/sales/estimates'
import customers from './routes/sales/customers'
import machines from './routes/sales/machines'
import exitVoucher from './routes/sales/exitVoucher'
import workCertificates from './routes/documents/workCertificates'
import guaranteeCertificates from './routes/documents/guaranteeCertificates'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'dashboard',
      component: () => import('@/views/Dashboard.vue'),
      meta: {
        layout: 'vertical',
        requireLogin: true,
        pageTitle: 'Tableau de bord',
        breadcrumb: [
          {
            text: 'Tableau de bord',
            active: true,
          },
        ],
      },
    },
    ...products,
    ...customers,
    ...machines,
    ...orders,
    ...invoices,
    ...estimates,
    ...purchaseOrders,
    ...exitVoucher,
    ...workCertificates,
    ...guaranteeCertificates,
    {
      path: '/settings',
      name: 'settings',
      requireLogin: true,
      component: () => import('@/views/settings/AccountSetting.vue'),
      meta: {
        pageTitle: 'Paramètres du compte',
        breadcrumb: [
          {
            text: 'Paramètres du compte',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireLogin) && store.state.isAuthenticated === false) {
    next({ name: 'login' })
  } else {
    next()
  }
})
export default router
