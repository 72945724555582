export default [
  {
    path: '/orders',
    name: 'orders',
    component: () => import('@/views/sales/orders/Index.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Bon de transfere',
      breadcrumb: [
        {
          text: 'Bon de transfere',
        },
        {
          text: 'Liste Bon de transfere',
          active: true,
        },
      ],
    },
  },
  {
    path: '/orders/create',
    name: 'create-order',
    component: () => import('@/views/sales/orders/Create.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Ajouter Commande Vente',
      breadcrumb: [
        {
          text: 'Commande Vente',
        },
        {
          text: 'Ajouter Commande Vente',
          active: true,
        },
      ],
    },
  },
  {
    path: '/orders/edit/:id',
    name: 'edit-order',
    props: true,
    component: () => import('@/views/sales/orders/Edit.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Modifier Commande Vente',
      breadcrumb: [
        {
          text: 'Commande Vente',
        },
        {
          text: 'Modifier Commande Vente',
          active: true,
        },
      ],
    },
  },
  {
    path: '/orders/show/:id',
    name: 'show-order',
    props: true,
    component: () => import('@/views/sales/orders/Show.vue'),
    meta: {
      requireLogin: true,
    },
  },

  {
    path: '/orders/print-all',
    name: 'order-print-all',
    props: true,
    component: () => import('@/views/sales/orders/PrintAll.vue'),
    meta: {
      requireLogin: true,
    },
  },

]
