import Vue from 'vue'
import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    banks: [],
    loading: false,
    error: null,
  },
  getters: {
    banks: state => state.banks,
    loading: state => state.loading,
    error: state => state.error,
  },
  mutations: {
    SET_BANKS(state, payload) {
      state.banks = payload
    },
    ADD_BANK(state, payload) {
      state.banks.unshift(payload)
    },
    UPDATE_BANK(state, payload) {
      const index = state.banks.findIndex(
        bank => bank.id === payload.id,
      )
      Vue.set(state.banks, index, payload)
    },
    DELETE_BANK(state, payload) {
      const index = state.banks.findIndex(
        bank => bank.id === payload,
      )
      state.banks.splice(index, 1)
    },
    SET_LOADING: (state, payload) => {
      state.loading = payload
    },
    SET_ERROR: (state, payload) => {
      state.error = payload
    },
    // eslint-disable-next-line no-return-assign
    CLEAR_ERROR: state => (state.error = null),
  },
  actions: {
    async getBanks({ commit }) {
      try {
        commit('SET_LOADING', true)
        const { data } = await axiosIns.get('/banks/')
        commit('SET_BANKS', data)
        commit('SET_LOADING', false)
      } catch (error) {
        commit('SET_LOADING', false)
      }
    },
    async addBank({ commit }, payload) {
      try {
        commit('CLEAR_ERROR')
        commit('SET_LOADING', true)
        const { data } = await axiosIns.post('/banks/create/', payload)
        commit('ADD_BANK', data)
        commit('SET_LOADING', false)
      } catch (error) {
        commit('SET_LOADING', false)
        commit('SET_ERROR', error)
      }
    },
    async updateBank({ commit }, payload) {
      try {
        const { data } = await axiosIns.put(
          `/banks/update/${payload.id}/`,
          payload,
        )
        commit('UPDATE_BANK', data)
        commit('SET_LOADING', false)
      } catch (error) {
        commit('SET_LOADING', false)
        commit('SET_ERROR', error)
      }
    },
    async deleteBank({ commit }, payload) {
      try {
        await axiosIns.delete(`/banks/delete/${payload}`)
        commit('DELETE_BANK', payload)
        commit('SET_LOADING', false)
      } catch (error) {
        commit('SET_LOADING', false)
        commit('SET_ERROR', error)
      }
    },
  },
}
