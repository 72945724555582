import Vue from 'vue'
import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    categories: [],
    loading: false,
    error: null,
  },
  getters: {
    categories: state => state.categories,
    loading: state => state.loading,
    error: state => state.error,
  },
  mutations: {
    SET_CATEGORIES(state, payload) {
      state.categories = payload
    },
    ADD_CATEGORY(state, payload) {
      state.categories.unshift(payload)
    },
    UPDATE_CATEGORY(state, payload) {
      const index = state.categories.findIndex(
        category => category.id === payload.id,
      )
      Vue.set(state.categories, index, payload)
    },
    DELETE_CATEGORY(state, payload) {
      const index = state.categories.findIndex(
        category => category.id === payload,
      )
      state.categories.splice(index, 1)
    },
    SET_LOADING: (state, payload) => {
      state.loading = payload
    },
    SET_ERROR: (state, payload) => {
      state.error = payload
    },
    // eslint-disable-next-line no-return-assign
    CLEAR_ERROR: state => (state.error = null),
  },
  actions: {
    async getCategories({ commit }) {
      try {
        commit('SET_LOADING', true)
        const { data } = await axiosIns.get('/categories/')
        commit('SET_CATEGORIES', data)
        commit('SET_LOADING', false)
      } catch (error) {
        commit('SET_LOADING', false)
      }
    },
    async addCategory({ commit }, payload) {
      try {
        commit('CLEAR_ERROR')
        commit('SET_LOADING', true)
        const { data } = await axiosIns.post('/categories/create/', payload)
        commit('ADD_CATEGORY', data)
        commit('SET_LOADING', false)
      } catch (error) {
        commit('SET_LOADING', false)
        commit('SET_ERROR', error)
      }
    },
    async updateCategory({ commit }, payload) {
      try {
        const { data } = await axiosIns.put(
          `/categories/update/${payload.id}/`,
          payload,
        )
        commit('UPDATE_CATEGORY', data)
        commit('SET_LOADING', false)
      } catch (error) {
        commit('SET_LOADING', false)
        commit('SET_ERROR', error)
      }
    },
    async deleteCategory({ commit }, payload) {
      try {
        await axiosIns.delete(`/categories/delete/${payload}`)
        commit('DELETE_CATEGORY', payload)
        commit('SET_LOADING', false)
      } catch (error) {
        commit('SET_LOADING', false)
        commit('SET_ERROR', error)
      }
    },
  },
}
