import Vue from 'vue'
import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    customers: [],
    loading: false,
    error: null,
  },
  getters: {
    customers: state => state.customers,
    loading: state => state.loading,
    error: state => state.error,
  },
  mutations: {
    SET_CUSTOMERS(state, payload) {
      state.customers = payload
    },
    ADD_CUSTOMER(state, payload) {
      state.customers.unshift(payload)
    },
    UPDATE_CUSTOMER(state, payload) {
      const index = state.customers.findIndex(
        customer => customer.id === payload.id,
      )
      Vue.set(state.customers, index, payload)
    },
    DELETE_CUSTOMER(state, payload) {
      const index = state.customers.findIndex(
        customer => customer.id === payload,
      )
      state.customers.splice(index, 1)
    },
    SET_LOADING: (state, payload) => {
      state.loading = payload
    },
    SET_ERROR: (state, payload) => {
      state.error = payload
    },
    // eslint-disable-next-line no-return-assign
    CLEAR_ERROR: state => (state.error = null),
  },
  actions: {
    async getCustomers({ commit }) {
      try {
        commit('SET_LOADING', true)
        const { data } = await axiosIns.get('/customers/')
        commit('SET_CUSTOMERS', data)
        commit('SET_LOADING', false)
      } catch (error) {
        commit('SET_LOADING', false)
      }
    },
    async addCustomer({ commit }, payload) {
      try {
        commit('CLEAR_ERROR')
        commit('SET_LOADING', true)
        const { data } = await axiosIns.post('/customers/create/', payload)
        commit('ADD_CUSTOMER', data)
        commit('SET_LOADING', false)
      } catch (error) {
        commit('SET_LOADING', false)
        commit('SET_ERROR', error)
      }
    },
    async updateCustomer({ commit }, payload) {
      try {
        const { data } = await axiosIns.put(
          `/customers/update/${payload.id}/`,
          payload,
        )
        commit('UPDATE_CUSTOMER', data)
        commit('SET_LOADING', false)
      } catch (error) {
        commit('SET_LOADING', false)
        commit('SET_ERROR', error)
      }
    },
    async deleteCustomer({ commit }, payload) {
      try {
        await axiosIns.delete(`/customers/delete/${payload}`)
        commit('DELETE_CUSTOMER', payload)
        commit('SET_LOADING', false)
      } catch (error) {
        commit('SET_LOADING', false)
        commit('SET_ERROR', error)
      }
    },
  },
}
