import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import createPersistedState from 'vuex-persistedstate'
import vat from '@/views/settings/vat/vatStore'
import paymentMethod from '@/views/settings/paymentMethod/paymentMethodStore'
import bank from '@/views/settings/bank/bankStore'
import product from '@/views/products/productStore'
import customer from '@/views/sales/customers/customerStore'
import supplier from '@/views/settings/supplier/supplierStore'
import driver from '@/views/settings/driver/driverStore'
import job from '@/views/settings/job/jobStore'
import governorate from '@/views/settings/state/stateStore'
import truck from '@/views/settings/truck/truckStore'
import category from '@/views/settings/category/categoryStore'
import expenseCategory from '@/views/settings/expenseCategory/expenseCategoryStore'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      id: '',
      username: '',
    },
    isAuthenticated: false,
    token: '',
  },
  mutations: {
    initializeStore(state) {
      if (localStorage.getItem('token')) {
        state.token = localStorage.getItem('token')
        state.isAuthenticated = true
      } else {
        state.token = ''
        state.isAuthenticated = false
      }
    },
    setToken(state, token) {
      state.token = token
      state.isAuthenticated = true
    },
    setRefresh(state, refresh) {
      state.refresh = refresh
    },
    removeToken(state) {
      state.token = ''
      state.user.id = ''
      state.user.username = ''
      state.isAuthenticated = false
    },
    setUser(state, user) {
      state.user = user
    },
  },
  modules: {
    app,
    appConfig,
    verticalMenu,
    vat,
    bank,
    governorate,
    category,
    job,
    truck,
    customer,
    supplier,
    driver,
    product,
    paymentMethod,
    expenseCategory,
  },
  strict: process.env.DEV,
  plugins: [createPersistedState()],
})
