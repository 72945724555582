import Vue from 'vue'
import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    paymentMethods: [],
    loading: false,
    error: null,
  },
  getters: {
    paymentMethods: state => state.paymentMethods,
    loading: state => state.loading,
    error: state => state.error,
  },
  mutations: {
    SET_PAYMENT_METHODS(state, payload) {
      state.paymentMethods = payload
    },
    ADD_PAYMENT_METHOD(state, payload) {
      state.paymentMethods.unshift(payload)
    },
    UPDATE_PAYMENT_METHOD(state, payload) {
      const index = state.paymentMethods.findIndex(
        category => category.id === payload.id,
      )
      Vue.set(state.paymentMethods, index, payload)
    },
    DELETE_PAYMENT_METHOD(state, payload) {
      const index = state.paymentMethods.findIndex(
        category => category.id === payload,
      )
      state.paymentMethods.splice(index, 1)
    },
    SET_LOADING: (state, payload) => {
      state.loading = payload
    },
    SET_ERROR: (state, payload) => {
      state.error = payload
    },
    // eslint-disable-next-line no-return-assign
    CLEAR_ERROR: state => (state.error = null),
  },
  actions: {
    async getPaymentMethods({ commit }) {
      try {
        commit('SET_LOADING', true)
        const { data } = await axiosIns.get('/payment/methods/')
        commit('SET_PAYMENT_METHODS', data)
        commit('SET_LOADING', false)
      } catch (error) {
        commit('SET_LOADING', false)
      }
    },
    async addPaymentMethod({ commit }, payload) {
      try {
        commit('CLEAR_ERROR')
        commit('SET_LOADING', true)
        const { data } = await axiosIns.post('/payment/methods/create/', payload)
        commit('ADD_PAYMENT_METHOD', data)
        commit('SET_LOADING', false)
      } catch (error) {
        commit('SET_LOADING', false)
        commit('SET_ERROR', error)
      }
    },
    async updatePaymentMethod({ commit }, payload) {
      try {
        const { data } = await axiosIns.put(
          `/payment/methods/update/${payload.id}/`,
          payload,
        )
        commit('UPDATE_PAYMENT_METHOD', data)
        commit('SET_LOADING', false)
      } catch (error) {
        commit('SET_LOADING', false)
        commit('SET_ERROR', error)
      }
    },
    async deletePaymentMethod({ commit }, payload) {
      try {
        await axiosIns.delete(`/payment/methods/delete/${payload}`)
        commit('DELETE_PAYMENT_METHOD', payload)
        commit('SET_LOADING', false)
      } catch (error) {
        commit('SET_LOADING', false)
        commit('SET_ERROR', error)
      }
    },
  },
}
