export default [
  {
    path: '/bon-de-sortie',
    name: 'exit-vouchers',
    component: () => import('@/views/sales/exitVoucher/Index.vue'),
    meta: {
      pageTitle: 'Bon de sortie',
      breadcrumb: [
        {
          text: 'Bon de sortie',
          active: true,
        },
      ],
      requireLogin: true,
    },
  },
  {
    path: '/bon-de-sortie/creation',
    name: 'create-exit-voucher',
    component: () => import('@/views/sales/exitVoucher/Create.vue'),
    meta: {
      pageTitle: 'Bon de sortie',
      requireLogin: true,
      breadcrumb: [
        {
          text: 'Création',
          active: true,
        },
      ],
    },
  },
  {
    path: '/bon-de-sortie/:id',
    name: 'show-exit-voucher',
    component: () => import('@/views/sales/exitVoucher/Show.vue'),
    meta: {
      requireLogin: true,

    },
  },

  {
    path: '/orders/print-all',
    name: 'exit-print-all',
    props: true,
    component: () => import('@/views/sales/exitVoucher/PrintAll.vue'),
    meta: {
      requireLogin: true,
    },
  },

  {
    path: '/bon-de-sortie/duplicate/:id',
    name: 'duplicate-order',
    props: true,
    component: () => import('@/views/sales/exitVoucher/Preview.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Ajouter bon de sortie',
      breadcrumb: [
        {
          text: 'Bon de sortie',
        },
        {
          text: 'Ajouter bon de sortie',
          active: true,
        },
      ],
    },
  },
  {
    path: '/bon-de-sortie/update/:id',
    name: 'edit-exit-voucher',
    component: () => import('@/views/sales/exitVoucher/Edit.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Modifier bon de sortie',
      breadcrumb: [
        {
          text: 'Bon de sortie',
        },
        {
          text: 'Modifier bon de sortie',
          active: true,
        },
      ],
    },
  },

]
