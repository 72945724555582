export default [
  {
    path: '/invoices',
    name: 'invoices',
    component: () => import('@/views/sales/invoices/Index.vue'),
    meta: {
      pageTitle: 'Gestion des factures',
      breadcrumb: [
        {
          text: 'Factures',
          active: true,
        },
      ],
      requireLogin: true,
    },
  },
  {
    path: '/invoices/create',
    name: 'create-invoice',
    component: () => import('@/views/sales/invoices/Create.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Gestion des factures',
      breadcrumb: [
        {
          text: 'Créer une facture',
          active: true,
        },
      ],
    },
  },
  {
    path: '/invoices/update/:id',
    name: 'edit-invoice',
    component: () => import('@/views/sales/invoices/Edit.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Gestion des factures',
      breadcrumb: [
        {
          text: 'Modifier facture',
          active: true,
        },
      ],
    },
  },
  {
    path: '/invoices/:id',
    name: 'show-invoice',
    component: () => import('@/views/sales/invoices/Show.vue'),
    meta: {
      requireLogin: true,
    },
  },
  {
    path: '/invoices/print-all',
    name: 'invoice-print-all',
    props: true,
    component: () => import('@/views/sales/invoices/PrintAll.vue'),
    meta: {
      requireLogin: true,
    },
  },
  {
    path: '/declared/invoices',
    name: 'declared-invoices',
    component: () => import('@/views/sales/declaredInvoices/Index.vue'),
    meta: {
      pageTitle: 'Gestion des factures déclaré',
      breadcrumb: [
        {
          text: 'Factures',
          active: true,
        },
      ],
      requireLogin: true,
    },
  },
  {
    path: '/declared/invoices/:id',
    name: 'show-declared-invoice',
    component: () => import('@/views/sales/declaredInvoices/Show.vue'),
    meta: {
      requireLogin: true,
    },
  },
  {
    path: '/declared/invoices/print-all',
    name: 'declared-invoice-print-all',
    props: true,
    component: () => import('@/views/sales/declaredInvoices/PrintAll.vue'),
    meta: {
      requireLogin: true,
    },
  },
]
