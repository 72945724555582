import Vue from 'vue'
import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    states: [],
    loading: false,
    error: null,
  },
  getters: {
    states: state => state.states,
    loading: state => state.loading,
    error: state => state.error,
  },
  mutations: {
    SET_STATES(state, payload) {
      state.states = payload
    },
    ADD_STATE(state, payload) {
      state.states.unshift(payload)
    },
    UPDATE_STATE(state, payload) {
      const index = state.states.findIndex(
        item => item.id === payload.id,
      )
      Vue.set(state.states, index, payload)
    },
    DELETE_STATE(state, payload) {
      const index = state.states.findIndex(
        item => item.id === payload,
      )
      state.states.splice(index, 1)
    },
    SET_LOADING: (state, payload) => {
      state.loading = payload
    },
    SET_ERROR: (state, payload) => {
      state.error = payload
    },
    // eslint-disable-next-line no-return-assign
    CLEAR_ERROR: state => (state.error = null),
  },
  actions: {
    async getStates({ commit }) {
      try {
        commit('SET_LOADING', true)
        const { data } = await axiosIns.get('/states/')
        commit('SET_STATES', data)
        commit('SET_LOADING', false)
      } catch (error) {
        commit('SET_LOADING', false)
      }
    },
    async addState({ commit }, payload) {
      try {
        commit('CLEAR_ERROR')
        commit('SET_LOADING', true)
        const { data } = await axiosIns.post('/states/create/', payload)
        commit('ADD_STATE', data)
        commit('SET_LOADING', false)
      } catch (error) {
        commit('SET_LOADING', false)
        commit('SET_ERROR', error)
      }
    },
    async updateState({ commit }, payload) {
      try {
        const { data } = await axiosIns.put(
          `/states/update/${payload.id}/`,
          payload,
        )
        commit('UPDATE_STATE', data)
        commit('SET_LOADING', false)
      } catch (error) {
        commit('SET_LOADING', false)
        commit('SET_ERROR', error)
      }
    },
    async deleteState({ commit }, payload) {
      try {
        await axiosIns.delete(`/states/delete/${payload}`)
        commit('DELETE_STATE', payload)
        commit('SET_LOADING', false)
      } catch (error) {
        commit('SET_LOADING', false)
        commit('SET_ERROR', error)
      }
    },
  },
}
