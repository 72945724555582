import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import {
  DropdownPlugin, ButtonGroupPlugin, ButtonPlugin, ButtonToolbarPlugin, IconsPlugin, BadgePlugin, InputGroupPlugin,FormInputPlugin, SpinnerPlugin,
} from 'bootstrap-vue'

Vue.use(DropdownPlugin)
Vue.use(ButtonGroupPlugin)
Vue.use(IconsPlugin)
Vue.use(ButtonPlugin)
Vue.use(ButtonToolbarPlugin)
Vue.use(BadgePlugin)
Vue.use(InputGroupPlugin)
Vue.use(FormInputPlugin)
Vue.use(SpinnerPlugin)
Vue.component(FeatherIcon.name, FeatherIcon)
