export default [
  {
    path: '/guarantee-certificates',
    name: 'guarantee-certificates',
    component: () => import('@/views/guaranteeCertificates/Index.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Certificat de garantie',
      breadcrumb: [
        {
          text: 'Liste',
        },
        {
          text: '#',
          active: true,
        },
      ],
    },
  },
  {
    path: '/guarantee-certificates/create',
    name: 'create-guarantee-certificate',
    component: () => import('@/views/guaranteeCertificates/Create.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Ajouter une certificat de garantie',
      breadcrumb: [
        {
          text: 'Nouveau',
        },
        {
          text: '#',
          active: true,
        },
      ],
    },
  },
  {
    path: '/guarantee-certificates/show/:id',
    name: 'show-guarantee-certificate',
    props: true,
    component: () => import('@/views/guaranteeCertificates/Show.vue'),
    meta: {
      requireLogin: true,
    },
  },
  {
    path: '/guarantee-certificates/update/:id',
    name: 'edit-guarantee-certificate',
    props: true,
    component: () => import('@/views/guaranteeCertificates/Update.vue'),
    meta: {
      requireLogin: true,
    },
  },
]
