export default [
  {
    path: '/products',
    name: 'products',
    component: () => import('@/views/products/Index.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Gestion des Produits',
      breadcrumb: [
        {
          text: 'Produits',
        },
        {
          text: 'Liste des Produits',
          active: true,
        },
      ],
    },
  },
  {
    path: '/products/create',
    name: 'create-product',
    component: () => import('@/views/products/Create.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Gestion des Produits',
      breadcrumb: [
        {
          text: 'Produits',
        },
        {
          text: 'Ajouter un Produit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/products/edit/:id',
    name: 'edit-product',
    props: true,
    component: () => import('@/views/products/Edit.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Gestion des Produits',
      breadcrumb: [
        {
          text: 'Produits',
        },
        {
          text: 'Modifier un Produit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/products/print/:id',
    name: 'print-product',
    props: true,
    component: () => import('@/views/products/Print.vue'),
    meta: {
      requireLogin: true,

    },
  },
]
