import Vue from 'vue'
import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    expenseCategories: [],
    loading: false,
    error: null,
  },
  getters: {
    expenseCategories: state => state.expenseCategories,
    loading: state => state.loading,
    error: state => state.error,
  },
  mutations: {
    SET_EXPENSE_CATEGORIES(state, payload) {
      state.expenseCategories = payload
    },
    ADD_EXPENSE_CATEGORY(state, payload) {
      state.expenseCategories.unshift(payload)
    },
    UPDATE_EXPENSE_CATEGORY(state, payload) {
      const index = state.expenseCategories.findIndex(
        category => category.id === payload.id,
      )
      Vue.set(state.expenseCategories, index, payload)
    },
    DELETE_EXPENSE_CATEGORY(state, payload) {
      const index = state.expenseCategories.findIndex(
        category => category.id === payload,
      )
      state.expenseCategories.splice(index, 1)
    },
    SET_LOADING: (state, payload) => {
      state.loading = payload
    },
    SET_ERROR: (state, payload) => {
      state.error = payload
    },
    // eslint-disable-next-line no-return-assign
    CLEAR_ERROR: state => (state.error = null),
  },
  actions: {
    async getExpenseCategories({ commit }) {
      try {
        commit('SET_LOADING', true)
        const { data } = await axiosIns.get('/expense/categories/')
        commit('SET_EXPENSE_CATEGORIES', data)
        commit('SET_LOADING', false)
      } catch (error) {
        commit('SET_LOADING', false)
      }
    },
    async addExpenseCategory({ commit }, payload) {
      try {
        commit('CLEAR_ERROR')
        commit('SET_LOADING', true)
        const { data } = await axiosIns.post('/expense/categories/create/', payload)
        commit('ADD_EXPENSE_CATEGORY', data)
        commit('SET_LOADING', false)
      } catch (error) {
        commit('SET_LOADING', false)
        commit('SET_ERROR', error)
      }
    },
    async updateExpenseCategory({ commit }, payload) {
      try {
        const { data } = await axiosIns.put(
          `/expense/categories/update/${payload.id}/`,
          payload,
        )
        commit('UPDATE_EXPENSE_CATEGORY', data)
        commit('SET_LOADING', false)
      } catch (error) {
        commit('SET_LOADING', false)
        commit('SET_ERROR', error)
      }
    },
    async deleteExpenseCategory({ commit }, payload) {
      try {
        await axiosIns.delete(`/expense/categories/delete/${payload}`)
        commit('DELETE_EXPENSE_CATEGORY', payload)
        commit('SET_LOADING', false)
      } catch (error) {
        commit('SET_LOADING', false)
        commit('SET_ERROR', error)
      }
    },
  },
}
