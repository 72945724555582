export default [
  {
    path: '/machines',
    name: 'machines',
    component: () => import('@/views/sales/machines/Index.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Machines vendue',
      breadcrumb: [
        {
          text: 'Machines',
        },
        {
          text: 'Liste des Machine Vendue',
          active: true,
        },
      ],
    },
  },
  {
    path: '/machines/create',
    name: 'create-machine',
    component: () => import('@/views/sales/machines/Create.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Machines vendue',
      breadcrumb: [
        {
          text: 'Machines',
        },
        {
          text: 'Ajouter Machine',
          active: true,
        },
      ],
    },
  },
]