import Vue from 'vue'
import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    trucks: [],
    loading: false,
    error: null,
  },
  getters: {
    trucks: state => state.trucks,
    loading: state => state.loading,
    error: state => state.error,
  },
  mutations: {
    SET_TRUCKS(state, payload) {
      state.trucks = payload
    },
    ADD_TRUCK(state, payload) {
      state.trucks.unshift(payload)
    },
    UPDATE_TRUCK(state, payload) {
      const index = state.trucks.findIndex(
        truck => truck.id === payload.id,
      )
      Vue.set(state.trucks, index, payload)
    },
    DELETE_TRUCK(state, payload) {
      const index = state.trucks.findIndex(
        truck => truck.id === payload,
      )
      state.trucks.splice(index, 1)
    },
    SET_LOADING: (state, payload) => {
      state.loading = payload
    },
    SET_ERROR: (state, payload) => {
      state.error = payload
    },
    // eslint-disable-next-line no-return-assign
    CLEAR_ERROR: state => (state.error = null),
  },
  actions: {
    async getTrucks({ commit }) {
      try {
        commit('SET_LOADING', true)
        const { data } = await axiosIns.get('/trucks/')
        commit('SET_TRUCKS', data)
        commit('SET_LOADING', false)
      } catch (error) {
        commit('SET_LOADING', false)
      }
    },
    async addTruck({ commit }, payload) {
      try {
        commit('CLEAR_ERROR')
        commit('SET_LOADING', true)
        const { data } = await axiosIns.post('/trucks/create/', payload)
        commit('ADD_TRUCK', data)
        commit('SET_LOADING', false)
      } catch (error) {
        commit('SET_LOADING', false)
        commit('SET_ERROR', error)
      }
    },
    async updateTruck({ commit }, payload) {
      try {
        const { data } = await axiosIns.put(
          `/trucks/update/${payload.id}/`,
          payload,
        )
        commit('UPDATE_TRUCK', data)
        commit('SET_LOADING', false)
      } catch (error) {
        commit('SET_LOADING', false)
        commit('SET_ERROR', error)
      }
    },
    async deleteTruck({ commit }, payload) {
      try {
        await axiosIns.delete(`/trucks/delete/${payload}`)
        commit('DELETE_TRUCK', payload)
        commit('SET_LOADING', false)
      } catch (error) {
        commit('SET_LOADING', false)
        commit('SET_ERROR', error)
      }
    },
  },
}
